<template>
	<!--header__join-button, header__login-button 클래스는 구글 태그매니저와 연동되어 있으므로 변경 시 유의하세요.-->
	<header v-if="headerOption.type === 'default'" class="header-default">
		<div class="header-wrapper">
			<img class="header__logo" src="@/assets/img/logo-new@2x.png" @click="goToHome" :alt="appData.appName">
			<nav class="header__pc-tab">
				<div class="header__tab-button text-elice-dx-neolli-bd on-cursor" :class="{ active: route.path.includes('/shop') }" 
					@click="toggleGlobalNavigation(false), goToShop()"
				>
					SHOP
				</div>
			</nav>
			<div class="header__account-info" v-if="isDataLoadingFinished">
				<div v-if="loginStatus.isLogin" class="header__account-button-wrapper">
					<!--로그인한 경우 -->
					<router-link class="header__pc-my-page text-elice-dx-neolli-bd"
						:class="{ active: route.path.includes('/mypage') }" to="/mypage">
						마이페이지
					</router-link>
					<div class="header__user-info">
						<span class="header__memname" @click="router.push({ name: 'Modify' })">
							<img src="@/assets/img/ic_profiles_ss@2x.png" alt="프로필 이미지">
							<strong>{{ loginStatus.memname }}</strong>님
						</span>
						<span class="header__mempoint" @click="router.push({ name:'Benefit'})">{{ loginStatus.mempoint.toLocaleString() }} M</span>
					</div>
					<button class="header__logout-button" @click="logout">로그아웃</button>
				</div>
				<div v-else class="header__account-button-wrapper">
					<!--로그아웃/비회원일 경우 -->
					<router-link to="/account/join/checkAdult" class="header__join-button">회원가입</router-link>
					<button class="header__login-button" @click="login">로그인</button>
				</div>
			</div>
			<img v-if="isGlobalNavActived" class="header__menu close_btn" src="@/assets/img/btn_close_w-black@2x.png"
				alt="close-btn" @click="toggleGlobalNavigation(false)" />
			<img v-else class="header__menu" src="@/assets/img/m_menu_btn@2x.png" alt="menu-btn"
				@click="toggleGlobalNavigation(true)" />
		</div>
		<globalNavigation />
	</header>
	<!-- 24/03/27 버거바 추가 -->
	<header v-else-if="headerOption.type === 'nameOnly'" class="header-name-only">
		<div class="header-wrapper">
			<img class="header__logo" src="@/assets/img/logo-new@2x.png" @click="goToHome" :alt="appData.appName">
			<span class="header__title text-purple text-elice-dx-neolli-bd">
				{{ headerOption.name }}
			</span>
			<img v-if="isGlobalNavActived" class="header__menu close_btn" src="@/assets/img/btn_close_w-black@2x.png"
				alt="close-btn" @click="toggleGlobalNavigation(false)" />
			<img v-else class="header__menu" src="@/assets/img/m_menu_btn@2x.png" alt="menu-btn"
				@click="toggleGlobalNavigation(true)" />
		</div>
		<globalNavigation />
	</header>
	<header v-else-if="headerOption.type === 'logoOnly'">
		<div class="header-wrapper">
			<img class="header__logo" src="@/assets/img/logo-new@2x.png" @click="goToHome" :alt="appData.appName">
		</div>
	</header>
	<header v-else-if="headerOption.type === 'noHeader'" style="display:none;"></header>
</template>

<script setup>
import { onMounted, ref, watch, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import {gtmCustomEvent} from '@/util/global'

import globalNavigation from '@/components/common/globalNavigation'
const store = useStore()
const route = useRoute()
const router = useRouter()
const isDataLoadingFinished = ref(computed(()=>store.getters.getIsDataLoadingFinished)) //로딩 끝났는지 체크
const appData = ref(computed(() => store.getters.getAppData))
const isGlobalNavActived = ref(computed(() => store.getters.getIsGlobalNavActived))
const headerOption = ref({ type: '', name: '' }) //헤더 형태를 최종적으로 관장함
const defaultHeaderOption = { type: 'default', name: '' } //기본 헤더
const headerOptionForExceptions = { //예외적 상황의 헤더
	//nameOnly: 페이지 이름만 있음 
	//logoOnly: 사이트 로고만 있음 
	//noHeader: 헤더 없음
	AccountLogin: { type: 'nameOnly', name: '로그인' },
	AccountCheckAdult: { type: 'nameOnly', name: '회원가입' },
	AccountSNSJoin: { type: 'nameOnly', name: '회원가입' },
	AccountTerms: { type: 'nameOnly', name: '회원가입' },
	AccountResult: { type: 'nameOnly', name: '회원가입' },
	ShopInspecting: { type: 'logoOnly', name: '' },
	ShopClosed: { type: 'logoOnly', name: '' },
	WindowPopup: { type: 'noHeader', name: '' },
	AccountCertify: { type: 'noHeader', name: '' },
	ShopPayLoading: { type: 'noHeader', name: '' },
	ShopDrop:{type: 'noHeader', name: ''}
}
const loginStatus = ref(computed(() => store.getters.getLoginStatus))
const setHeaderOption = async () => {
	await router.isReady()
	headerOptionForExceptions[route.name] === undefined ?
		headerOption.value = defaultHeaderOption :
		headerOption.value = headerOptionForExceptions[route.name]
}
const login = async () => {
	gtmCustomEvent('loginButtonClick')
	localStorage.setItem('nextPageName', route.name)
	localStorage.setItem('nextPageQuery', JSON.stringify(route.query))
	localStorage.setItem('nextPageForNotJoined', 'AccountCheckAdult')
	await router.push({ name: 'AccountLogin' })

}
const logout = () => {
	window.open('/logout', '_self')
}
const toggleGlobalNavigation = (boolean) => {
	store.commit('toggleGlobalNavigation', boolean)
}
const goToHome = async  () => {
	await router.push({name:'Home'})
	location.reload(true)
}
const goToShop = () =>{
	router.push('/shopMain')
}
watch(route, async () => {
	setHeaderOption()
})

onMounted(async () => {
	setHeaderOption()
})
</script>

<style scoped>
header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
	height: 80px;
	width: 100%;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09);
	background: #fff;
}

header .header-wrapper {
	height: 100%;
	padding: 24px;
	display: flex;
	max-width: 1200px;
	margin: 0 auto;
}

.header__logo {
	vertical-align: middle;
	display: inline-block;
	height: 40px;
	margin: 0px 16px 0 0;
}
.header__logo:hover{
	cursor: pointer;
}
.header__title {
	vertical-align: middle;
	display: inline-block;
	margin: 10px 0 0 0;
	font-size: 18px;
	letter-spacing: -0.9px;
	font-weight: 600;
	height: 20px;
}

.header__title--name-with-right-menu {
	display: inline-block;
}

.header__title.header__title--name-with-right-menu {
	font-size: 18px;
	letter-spacing: -0.9px;
}

.header__pc-tab {
	display: flex;
	gap: 36px;
	margin: 8px 0 0 0;
	height: 24px;
}

.header__pc-tab .header__tab-button {
	font-size: 18px;
	line-height: 1.56;
	letter-spacing: -0.9px;
	text-align: center;
	color: #afb9c3;
	height: 24px;
	display: block;
	line-height: 24px;
}
.header__pc-tab .header__tab-button:hover{
	cursor:pointer;
}
.header__pc-tab .header__tab-button.active {
	color: #6035f1;
	border-bottom: 2px solid #6035f1;
}

.header__account-info {
	margin-left: auto;
}

.header__account-button-wrapper {
	display: none;
}

.header__pc-my-page {
	display: none;
}

.header__menu {
	display: block;
	position: absolute;
	right: 30px;
	top: 28px;
	width: 24px;
	height: 24px;
}
.header__menu:hover{
	cursor: pointer;
}
.header__menu.close_btn {
	width: 24px;
	height: 24px;
}

@media screen and (min-width:1200px) {
	header {
		height: 120px;
	}

	.header__logo {
		height: 72px;
		margin: 0px 24px 0 0;
	}

	.header__title {
		font-size: 24px;
		letter-spacing: -1.2px;
		height: 26px;
		margin-top: 26px;
		line-height: 24px;
	}

	.header__pc-tab {
		margin: 26px 0 0 0;
	}

	.header__menu {
		display: none;
	}
	.header__user-info .header__memname:hover{
		cursor: pointer;
	}
	.header__user-info .header__memname:before,
	.header__user-info .header__memname:after {
		display: inline-block;
		content: '|';
		font-family: 'Noto Sans KR';
	}

	.header__user-info .header__memname:before {
		margin-right: 20px;
	}

	.header__user-info .header__memname:after {
		margin-left: 20px;
	}

	.header__user-info .header__memname img {
		width: 30px;
		height: 30px;
		margin-right: 8px;
		vertical-align: middle;
	}

	.header__user-info .header__memname strong {
		font-family: 'Noto Sans KR';
		font-weight: bold;
	}

	.header__user-info .header__mempoint {
		display: inline-block;
		text-align: right;
		font-family: 'robotoBD';
		padding-left: 20px;
	}
	.header__user-info .header__mempoint:hover{
		cursor:pointer;
	}
	.header__account-button-wrapper {
		display: flex;
		margin-top: 16px;
		gap: 20px;
		align-items: center;
	}

	.header__join-button {

		font-size: 18px;
		font-weight: bold;
		line-height: 1.44;
		letter-spacing: -0.9px;
		color: #17191d;
	}

	.header__join-button:after {
		content: '|';
		margin: 0 0 0 20px;
	}

	.header__login-button,
	.header__logout-button {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 20px;
		border: solid 1px #17191d;
		font-size: 18px;
		font-weight: 500;
		letter-spacing: -0.9px;
		width: 114px;
		height: 40px;
	}

	.header-default .header__title {
		display: none;
	}

	.header__pc-my-page {
		display: flex;
		width: 114px;
		height: 40px;
		border-radius: 8px;
		border: solid 1px #afb9c3;
		background-color: #fff;
		justify-content: center;
		align-items: center;
		font-size: 18px;
		letter-spacing: -0.9px;
		color: #afb9c3;
	}

	.header__pc-my-page.active {
		color: #6035f1;
		border: 1px solid #6035f1;
	}
}
</style>