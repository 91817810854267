'use strict'
import store from '@/store'

let timeoutID = null
const popupOptions = {
	payloadConfirmEvent:null, //popupByPayload 확인 이벤트
	optionsByCode:{
		S0000:{
			popup:true,
			text:'데이터 연결이 복구 되었습니다.',
		},
		ES1000:{
			popup:false,
			nextEvent:null
		},
		E0400:{
			popup:true,
			text:'잘못된 요청입니다. 다시 시도 해주세요.',
			confirmEvent(){window.open('/', '_self')}
		},
		E0403:{
			popup:true,
			text:'잘못된 요청입니다. 다시 시도 해주세요.',
			confirmEvent(){window.open('/', '_self')}
		},
		E0404:{
			popup:true,
			text:'존재하지 않는 페이지 입니다.',
			confirmEvent() {
				window.open('/', '_self')
			}
		},
		E0406:{
			popup:true,
			text:'잘못된 요청입니다. 다시 시도 해주세요.',
			confirmEvent(){window.open('/', '_self')}
		},
		E0500:{
			popup:true,
			text:'서버에 문제가 발생하여 요청을 처리 할 수 없습니다.',
			confirmEvent(){window.open('/', '_self')}
		},
		E0502:{
			popup:true,
			text:'동시 접속자 수가 많아 서비스에 접속 할 수 없습니다.',
			confirmEvent(){window.open('/', '_self')}
		},
		E0503:{
			popup:true,
			text:'관리자에 의해 서비스가 일시적으로 중단되었습니다.',
			confirmEvent(){window.open('/', '_self')}
		},
		E0504:{
			popup:true,
			text:'처리시간이 오래 경과하여 서버가 연결을 끊었습니다.',
			confirmEvent(){window.open('/', '_self')}
		},
		E9901:{
			popup:true,
			text:'네트워크 환경이 불안정 합니다. 다시 시도 해주세요.',
			confirmEvent(){window.open('/', '_self')}
		},
		ES2001:{
			popup:true,
			text:'정보 요청에 실패하였습니다. 다시 시도 해주세요.',
		},
		ES2002:{
			popup:true,
			text:'인증하신 실명정보로 이미 가입된 계정이 있습니다.',
			// confirmEvent(){window.open("/account")}
		},
		ES2006:{
			popup:true,
			text:'인증하신 실명정보로 이미 가입된 계정이 있습니다.',
			// confirmEvent(){window.open("/account")}
		},
		ES3001:{
			popup:true,
			text:'본인인증에 실패하였습니다. 다시 시도 해주세요.',
		},
		ES3002:{
			popup:true,
			text:'정상 처리 하지 못하였습니다. 다시 시도 해주세요.',
		},
		ES3003:{
			popup:true,
			text:'게임브릿지는 18세 이상 고객만 사용 가능합니다.',
		},
		ES3004:{
			popup:true,
			text:'탈퇴 후 30일 이내에는 재가입이 불가합니다.',
			confirmEvent(){window.open("/")}
		},
		ES3005:{
			popup:true,
			text:'본인확인 중 오류가 발생하였습니다.',
		},
		ES3006:{
			popup:true,
			text:'인증 앱 삭제 시 서비스를 이용하실 수 없습니다. 재설치 후 이용 해주세요.',
		},
		ES3007:{
			popup:true,
			text:'서버에 문제가 발생하여 요청을 처리 할 수 없습니다. 잠시 후 이용 해주세요.',
			confirmEvent(){window.open("/")}
		},
		ES3008:{
			popup:true,
			text:'동시 접속자 수가 많아 서비스에 접속 할 수 없습니다. 잠시 후 이용 해주세요.',
		},
		ES3009:{
			popup:true,
			text:'제공하지 않는 인증 서비스입니다.',
		},
		ES3010:{
			popup:true,
			text:'ARS 서비스에 오류가 발생 하였습니다. 잠시 후 이용 해주세요.',
			confirmEvent(){window.open("/")}
		},
		ES3011:{
			popup:true,
			text:'PASS 인증을 사용할 수 없습니다. 문자(SMS) 인증으로 다시 시도해 주세요.',
		},
		ES3012:{
			popup:true,
			text:'PASS 서비스 미가입자입니다. 가입 후 다시 시도해주세요.',
		},
		ES3013:{
			popup:true,
			text:'PASS 앱이 설치되지 않았습니다. 앱 설치 후 다시 시도해 주세요.',
		},
		ES3014:{
			popup:true,
			text:'PUSH 발송에 실패하였습니다. 잠시 후 다시 시도해 주세요.',
			confirmEvent(){window.open("/")}
		},
		ES3015:{
			popup:true,
			text:'통신사를 알뜰폰으로 선택하여 다시 시도해 주세요.',
		},
		ES3016:{
			popup:true,
			text:'본인확인서비스 이용이 차단되어 있습니다. 차단 해지 후 다시 시도해 주세요.',
		},
		ES3017:{
			popup:true,
			text:'메시지 발송이 차단되어 있습니다. 차단 해지 후 다시 이용해 주세요.',
		},
		ES3018:{
			popup:true,
			text:'SKT 휴대폰 인증보호 서비스 앱이 설치되지 않았습니다. 앱 설치 후 다시 시도해 주세요.',
		},
		ES3101:{
			popup:true,
			text:'상품을 찾을 수 없습니다. (사유 : 잘못된 주소 혹은 판매 중지)',
			confirmEvent(){window.open(`/shop?game=${localStorage.getItem('gameName')}`, '_self')}
		},
		ES3102:{
			popup:true,
			text:'구매 가능 기간이 지난 상품입니다.',
			confirmEvent(){window.open(`/shop?game=${localStorage.getItem('gameName')}`, '_self')}
		},
		ES3103:{
			popup:true,
			text:'구매 가능 수량이 없습니다.',
		},
		ES3104:{
			popup:true,
			text:'계정당 구매 제한 가능 수량을 초과했습니다.'
		},
		ES3111:{
			popup:true,
			text:'보유 마일리지를 초과하여 사용은 불가능합니다.',
		},
		ES3112:{
			popup:true,
			text:'최소 결제 가능 금액은 1,000원 이상이어야 합니다.',
		},
		ES3113:{
			popup:true,
			text:'구매유의사항 및 청약철회 불가 약관에 동의해주세요.',
		},
		ES3114:{
			popup:true,
			text:'사용가능한 마일리지 범위가 아닙니다.',
		},
		ES3115:{
			popup:true,
			text:'주문을 처리할 수 없습니다. 페이지를 새로고침 후 다시 시도해주세요.',
		},
		ES3116:{
			popup:true,
			text:'주문을 처리할 수 없습니다. 페이지를 새로고침 후 다시 시도해주세요.',
		},
		ES3117: {
			popup:true,
			text:'주문을 처리할 수 없습니다. 페이지를 새로고침 후 다시 시도해주세요.',
		},
		ES3118:{
			popup:true,
			text:'주문을 처리할 수 없습니다. 페이지를 새로고침 후 다시 시도해주세요.',
		},
		ES3119:{
			popup:true,
			text:'마일리지 결제를 처리할 수 없습니다. 페이지를 새로고침 후 다시 시도 해주세요.',
		},
		ES3201:{
			popup:true,
			text:'주문을 처리할 수 없습니다. 페이지를 새로고침 후 다시 시도해주세요.',
		},
		ES3211:{
			popup:true,
			text:'결제를 처리할 수 없습니다. 페이지를 새로고침 후 다시 시도해주세요.',
		},
		ES3212:{
			popup:true,
			text:'결제를 처리할 수 없습니다. 페이지를 새로고침 후 다시 시도해주세요.',
		},
		ES3213:{
			popup:true,
			text:'결제를 처리할 수 없습니다. 페이지를 새로고침 후 다시 시도해주세요.',
		},
		ES3214:{
			popup:true,
			text:'결제를 처리할 수 없습니다. 페이지를 새로고침 후 다시 시도해주세요.',
		},
		ES3215:{
			popup:true,
			text:'결제를 처리할 수 없습니다. 페이지를 새로고침 후 다시 시도해주세요.',
		},
		ES3251:{
			popup:true,
			text:'결제를 처리할 수 없습니다. 페이지를 새로고침 후 다시 시도해주세요.',
		},
		ES3252:{
			popup:true,
			text:'결제를 처리할 수 없습니다. 페이지를 새로고침 후 다시 시도 해주세요.',
			confirmEvent(){window.open('/shop/purchase', '_self')}
		},
		ES3253:{
			popup:true,
			text:'결제 제한 한도 금액을 초과하였습니다. 결제 수단별 한도를 안내 받으시겠습니까?',
			type:'yesOrNoSelect',
			confirmEvent(){
				store.commit('shopPage/setIsLimitTableOpened')
				const limitTableScrollPos = document.querySelector('.shop-purchase__check-limits').getBoundingClientRect().top
				const headerHeight = document.querySelector('.header-wrapper').getBoundingClientRect().height
				const velocityByDevice = window.innerWidth < 1200 ? 60 : 10
				timeoutID = setTimeout(()=>{ // 결제한도 테이블이 로딩되는 시점을 기다려야 해서 지연시간을 줍니다.
					window.scrollTo({top:limitTableScrollPos - headerHeight - velocityByDevice, left:0, behavior:'smooth'})
					timeoutID = null
					clearTimeout(timeoutID)
				}, 100)
			}
		},
		ES3254:{
			popup:true,
			text:'개인 카드 한도 혹은 통장잔액이 결제 금액보다 부족합니다.',
		},
		ES3299:{
			popup:true,
			text:'결제를 처리할 수 없습니다. 페이지를 새로고침 후 다시 시도 해주세요.',
		},
		ES3301:{
			popup:true,
			text:'이미 구매확정 된 건 입니다.',
			confirmEvent(){window.location.reload(true)}
		},
		ES3302:{
			popup:true,
			text:'이미 구매확정 된 건 입니다.',
			confirmEvent(){window.location.reload(true)}
		},
		ES3311:{
			popup:true,
			text:'계좌환불 시 예금주 성함과 계정(본인인증)정보가 일치해야 합니다.',
		},
		ES3312:{
			popup:true,
			text:'결제 취소를 처리 할 수 없습니다. 다시 시도 해주세요.',
		},	
		ES3313:{
			popup:true,
			text:'잘못된 결제 취소 대상입니다. 다시 시도 해주세요.',
		},
		ES3314:{
			popup:true,
			text:'구매 확정 이후 결제취소는 불가합니다.',
		},
		ES3401:{
			popup:true,
			text:'환불 요청 정보가 올바르지 않습니다. 다시 시도 해주세요.'
		},
		ES3402:{
			popup:true,
			text:'환불 요청 정보가 올바르지 않습니다. 다시 시도 해주세요.'
		},
		ES3403:{
			popup:true,
			text:'환불 요청 정보가 올바르지 않습니다. 다시 시도 해주세요.'
		},
		ES3405:{
			popup:true,
			text:'잘못된 환불 대상입니다. 다시 시도 해주세요.'
		},
		ES3406:{
			popup:true,
			text:'환불 요청이 완료 된 건 입니다.'
		},
		ES3407:{
			popup:true,
			text:'잘못된 데이터 입니다. 다시 시도 해주세요.'
		},
		ES3511:{
			popup:true,
			text:'구매확정 하지 않은 상품과 환불 진행중인 건이 있는 경우에는 탈퇴가 불가합니다.'
		},
		ES3512:{
			popup:true,
			text:'구매확정 하지 않은 상품과 환불 진행중인 건이 있는 경우에는 탈퇴가 불가합니다.'
		},
		ES3601:{
			popup:true,
			text:'요청에 실패 하였습니다. 다시 시도 해주세요.'
		},
		ES3602:{
			popup:true,
			text:'요청에 실패 하였습니다. 다시 시도 해주세요.'
		},
		ES5000:{ // API를 요청함과 동시에 로그인 상태 여부를 체크할 때 사용
			popup:true,
			text:'로그인이 필요한 서비스입니다.',
			confirmEvent(){window.open('/account', '_self')}
		},
		ES5001:{
			popup:true,
			text:'등록되지 않은 가입정보 입니다. 다시 시도 해주세요.'
		},
		ES5002:{
			popup:true,
			text:'정보 요청에 실패하였습니다. 다시 시도 해주세요.'
		},
		loginRequired:{ // API를 요청하지 않고 로그인 상태 여부를 state 에서 선행체크할때 사용
			popup:true,
			text:'로그인이 필요한 서비스입니다.',
			confirmEvent(){window.open('/account', '_self')}
		},
		withdraw:{
			popup:true,
			text:'탈퇴 후 30일 이내에는 재가입이 불가합니다.',
			confirmEvent(){window.open("/logout", "_self")}
		}
	},
}

export default popupOptions