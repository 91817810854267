export default [
    {
        path: "/mypage",
        redirect: { name: "History" },
        name: "MyPage",
        component: () => import("@/components/myPage"),
        children: [
            {
                path: "/mypage/history",
                name: "History",
                component: () => import("@/components/mypage/myHistory"),
                meta: {
                    title: "구매내역",
                },
            },
            {
                path: "/mypage/history/detail",
                name: "HistoryDetail",
                component: () => import("@/components/mypage/myHistoryDetail"),
                meta: {
                    title: "구매상세",
                },
            },
            {
                path: "/mypage/holdingCode",
                name: "HoldingCode",
                component: () => import("@/components/mypage/myHoldingCode"),
                meta: {
                    title: "보유코드",
                },
            },
            {
                path: "/mypage/benefit",
                name: "Benefit",
                component: () => import("@/components/mypage/myBenefitManagement"),
                meta: {
                    title: "혜택관리",
                },
            },
            {
                path: "/mypage/inquiry",
                name: "Inquiry",
                component: () => import("@/components/mypage/myInquiry"),
                meta: {
                    title: "문의내역",
                },
            },
            {
                path: "/mypage/inquiry/detail",
                name: "InquiryDetail",
                component: () => import("@/components/mypage/myInquiryDetail"),
                meta: {
                    title: "문의상세",
                },
            },
            {
                path: "/mypage/userinfo/cancel",
                name: "Cancel",
                component: () => import("@/components/mypage/myUserCancel"),
                meta: {
                    title: "회원탈퇴",
                },
            },
            {
                path: "/mypage/userinfo/modify",
                name: "Modify",
                component: () => import("@/components/mypage/myUserInfoModify"),
                meta: {
                    title: "회원정보",
                },
            },
        ],
    },
];
