import axios from "axios";
import router from "@/router";
import {gtmCustomEvent} from '@/util/global'
const apiURL = process.env.VUE_APP_API;

const date = new Date();
const today = date.toISOString().split("T")[0];
const aMonthBefore = date.setMonth(date.getMonth() - 1);
export const myPage = {
    namespaced: true,
    state: {
        // 진행상태 ingstatus - 0전체, 1결제완료, 3구매확정, 8환불대기, 9환불완료
        pageSearchOption: {
            endperiod: today,
            ingstatus: 0,
            pageNum: 1,
            pageSize: 15,
            startperiod: new Date(aMonthBefore).toISOString().split("T")[0],
        },
        pageSearchOptionDate: {
            nowdate: "",
        },
        // 구매내역이 default, page OnMounted시 설정
        pageSearchOptionStatus: {
            title: "",
            list: [],
        },
        myPageShopHistoryData: {
            total: 0,
            list: [],
        },
        myPageShopHistoryDetail: {
            orderid: "",
            regdate: "",
            statusname: "",
            statusdate: "",
            gamename: "",
            itemid: 1,
            itemname: "",
            imgurl: "",
            itemcnt: 1,
            amount: 1000,
            usedpoint: 0,
            paymethod: "",
            isquestiondate: "",
            isconfirmday: 0,
            itemtype: 1,
            elapseday: 0,
            elapsemonth: 0,
        },
        bankRefundInfo: {
            possible: true,
            code: "",
            message: "",
            data: {
                refundamount: 3000,
                refundfee: 300,
                refundfeerate: 10,
                finalamount: 2700,
            },
        },
        bankRefundPayload: {
            accountholder: "",
            bankaccount: "",
            bankid: "0",
            orderid: "0",
            refundfee: "0",
            refundprice: "0",
        },
        bankRefundByCode: {
            S0000:
                "계정 도용으로 인한 사고 방지를 위해 " +
                "\n" +
                "환불계좌는​ 가입 시 본인 인증 정보와 일치해야만 합니다.",
            ES3404: `수수료 제외 후 최종 환불 금액이 1,000원 \n 이하로 환불이 불가합니다.`,
        },
        holdingCodeData: {
            total: 0,
            list: [],
        },
        holdingCodeDetail: {},
        holdingCodeDetailSearchOption: {
            orderid: "",
            pageNum: 1,
            pageSize: 15,
        },
        myBenefits: {
            mempoint: 0,
            delpoint: 0,
            memname: "",
            monthend: "",
            infoList: {
                endRow: 0,
                hasNextPage: false,
                hasPreviousPage: false,
                isFirstPage: true,
                isLastPage: true,
                list: [],
                navigateFirstPage: 0,
                navigateLastPage: 0,
                navigatePages: 8,
                navigatepageNums: [],
                nextPage: 0,
                pageNum: 1,
                pageSize: 15,
                pages: 0,
                prePage: 0,
                size: 0,
                startRow: 0,
                total: 0,
            },
        },
        // 1 구글
        userInfo: {
            name: "",
            regdate: "",
            socialtype: 1,
            socialname: "",
            emailflag: 1,
            phoneflag: 1,
        },
        methodId: 4,
        inquiryData: {
            total: 0,
            // "list": [{
            // 	"askid": "",
            // 	"askstatus": "",
            // 	"asktitle": "",
            // 	"regdate": "",
            // 	"resdate": "",
            // 	"rownum": 0
            // }],
        },
        inquiryDetail: {},
        isWithDrawPossiple: {
            confirmcnt: 0,
            refundcnt: 0,
            resultcd: "string",
        },
        withDrawReason: [],
    },
    mutations: {
        setMyPageShopHistoryData(state, payload) {
            state.myPageShopHistoryData = {
                ...state.myPageShopHistoryData,
                ...payload,
            };
        },
        setPageSearchOptionDate(state, payload) {
            state.pageSearchOptionDate = payload;
        },
        addMyPageShopHistoryDataMore(state, payload) {
            state.myPageShopHistoryData.list = [
                ...state.myPageShopHistoryData.list,
                ...payload,
            ];
        },
        setPageSearchOption(state, newPageSearchOption) {
            state.pageSearchOption = {
                ...state.pageSearchOption,
                ...newPageSearchOption,
            };
        },
        setPageSearchOptionStatus(state, payload) {
            state.pageSearchOptionStatus = { title: "", list: [] };
            state.pageSearchOptionStatus = payload;
        },
        setMyPageShopHistoryDetail(state, payload) {
            state.myPageShopHistoryDetail = {
                ...state.myPageShopHistoryDetail,
                ...payload,
            };
        },
        setBankRefundInfo(state, newBankRefundInfo) {
            state.bankRefundInfo = {
                ...state.bankRefundInfo,
                ...newBankRefundInfo,
            };
        },
        setBankRefundPayload(state, newBankRefundPayload) {
            state.bankRefundPayload = {
                ...state.bankRefundPayload,
                ...newBankRefundPayload,
            };
        },
        setBankRefundMessage(state) {
            state.bankRefundInfo.message =
                state.bankRefundByCode[state.bankRefundInfo.code];
        },
        setHoldingCodeData(state, payload) {
            state.holdingCodeData = payload;
        },
        setHoldingCodeDetail(state, payload) {
            state.holdingCodeDetail = payload;
        },
        setHoldingCodeDetailSearchOption(state, payload) {
            state.holdingCodeDetailSearchOption = {
                ...state.holdingCodeDetailSearchOption,
                ...payload,
            };
        },
        addHoldingCodeDataMore(state, payload) {
            state.holdingCodeData.list = [
                ...state.holdingCodeData.list,
                ...payload,
            ];
        },
        addHoldingCodeDetailMore(state, payload) {
            state.holdingCodeDetail.list = [
                ...state.holdingCodeDetail.list,
                ...payload,
            ];
        },
        setMyBenefits(state, payload) {
            state.myBenefits = { ...state.myBenefits, ...payload };
        },
        addMyBenefitsMore(state, payload) {
            state.myBenefits.list = [...state.myBenefits.list, ...payload];
        },
        setUserInfo(state, payload) {
            state.userInfo = { ...state.userInfo, ...payload };
        },
        setInquiryData(state, payload) {
            state.inquiryData = payload;
        },
        setMethodId(state, methodId) {
            state.methodId = methodId;
        },
        addInquiryDataMore(state, payload) {
            state.inquiryData.list = [...state.inquiryData.list, ...payload];
        },
        setInquiryDetail(state, payload) {
            state.inquiryDetail = payload;
        },
        setIsWithDrawPossible(state, payload) {
            state.isWithDrawPossiple = payload;
        },
        setWithDrawReason(state, payload) {
            state.withDrawReason = payload;
        },
    },
    actions: {
        async getMyPageShopHistoryData({ commit, dispatch, state }) {
            try {
                const response = await axios.post(
                    `${apiURL}/api/v1/mypage/buys`,
                    state.pageSearchOption
                );

                if (response.data.code !== "S0000") {
                    throw response.data.code;
                } else {
                    commit("setMyPageShopHistoryData", response.data.data);
                    commit(
                        "setPageSearchOptionDate",
                        response.data.data.nowdate
                    );
                }
            } catch (code) {
                dispatch("popupProcessor/setSelectedPopupByCode", code, {
                    root: true,
                });
            }
        },
        async getMyPageShopHistoryDetail({ commit, dispatch }, orderid) {
            try {
                const response = await axios.get(
                    `${apiURL}/api/v1/mypage/buy/detail?orderid=${orderid}`
                );
                if (response.data.code !== "S0000") {
                    dispatch("popupProcessor/setSelectedPopupByCode",
                        response.data.code,
                        { root: true }
                    );
                    commit("startDataLoading", { root: true });
                    return;
                } else if (response.data.data === null) {
                    dispatch("popupProcessor/setSelectedPopupByCode", "ES404", {
                        root: true,
                    });
                    return;
                } else {
                    commit("setMyPageShopHistoryDetail", response.data.data);
                }
            } catch (e) {
                console.log(e);
            }
        },
        // 구매확정
        async updateMyPageConfirm({ commit, dispatch }, orderid) {
            try {
                const response = await axios.put(
                    `${apiURL}/api/v1/mypage/confirm?orderid=${orderid}`
                );
                if (response.data.code !== "S0000") {
                    dispatch("popupProcessor/setSelectedPopupByCode",
                        response.data.code,
                        { root: true }
                    );
                } else {
                    commit("setMyPageShopHistoryDetail", {
                        statusname: "구매확정",
                        isconfirmday: 0,
                        elapseday: 0,
                        elapsemonth: 0,
                    });
                    dispatch("popupProcessor/setSelectedPopupByPayload",
                        {
                            type: "yesOnly",
                            title: "알림",
                            text: "구매확정이 완료 되었습니다.",
                            payloadConfirmEvent() {
                                commit("setMyPageShopHistoryDetail", {
                                    isconfirmday: 0,
                                });
                                dispatch("getMyPageShopHistoryDetail", orderid);
                                window.location.reload(true);
                                // 사파리에서 안먹음 router.go
                                // router.go(0)
                            },
                        },
                        { root: true }
                    );
                }
            } catch (error) {
                console.log(error);
            }
        },
        // 결제 취소
        async getPayCancel({ commit, dispatch, state }) {
            try {
                const response = await axios.post(
                    `${apiURL}/api/v1/pay/cancel`,
                    { orderid: state.myPageShopHistoryDetail.orderid }
                );
                if (response.data.code !== "S0000") {
                    dispatch("popupProcessor/setSelectedPopupByCode",
                        response.data.code,
                        { root: true }
                    );
                } else {
                    // 구매취소처리 성공시 state 업데이트 후 일반 팝업 띄움, state 업데이트
                    commit("setMyPageShopHistoryDetail", {
                        statusname: "환불완료",
                        isconfirmday: 0,
                        elapseday: 0,
                        elapsemonth: 0,
                    });
                    dispatch(
                        "popupProcessor/setSelectedPopupByPayload",
                        {
                            type: "yesOnly",
                            title: "알림",
                            text: "구매취소가 완료 되었습니다.",
                            payloadConfirmEvent() {
                                window.location.reload(true);
                            },
                        },
                        { root: true }
                    );
					gtmCustomEvent('purchaseCancel')
                }
            } catch (e) {
                console.error(e);
            }
        },
        //테스터 결제 취소
        async getTesterPayCancel({ commit, dispatch, state }){
            try{
                const response = await axios.post(`${apiURL}/api/v1/pay/cancel/tester`, { orderid: state.myPageShopHistoryDetail.orderid })
                if (response.data.code !== "S0000") {
                    throw response.data.code
                }
                commit("setMyPageShopHistoryDetail", {
                    statusname: "환불완료",
                    isconfirmday: 0,
                    elapseday: 0,
                    elapsemonth: 0,
                });
                dispatch("popupProcessor/setSelectedPopupByPayload", {
                    type: "yesOnly",
                    title: "알림",
                    text: "구매취소가 완료 되었습니다.",
                    payloadConfirmEvent() {
                        window.location.reload(true);
                    },
                },
                { root: true });
            }
            catch(code){
                dispatch("popupProcessor/setSelectedPopupByCode", code, { root: true });
            }
        },
        async getOnlyPointPayCancel({state, dispatch }) {
            try {
                const response = await axios.put(
                    `${apiURL}/api/v1/pointpay/cancel?orderid=${state.myPageShopHistoryDetail.orderid}`
                );
                if (response.data.code !== "S0000") {
                    dispatch("popupProcessor/setSelectedPopupByCode",
                        response.data.code,
                        { root: true }
                    );
                } else {
                    // 구매취소처리 성공시 일반 팝업
                    dispatch(
                        "popupProcessor/setSelectedPopupByPayload",
                        {
                            type: "yesOnly",
                            title: "알림",
                            text: "구매취소가 완료 되었습니다.",
                            payloadConfirmEvent() {
                                dispatch(
                                    "getMyPageShopHistoryDetail",
                                    state.myPageShopHistoryDetail.orderid
                                );
                                window.location.reload();
                            },
                        },
                        { root: true }
                    );
					gtmCustomEvent('purchaseCancel')
                }
            } catch (e) {
                console.error(e);
            }
        },
        async getMyPageShopHistoryDataMore({ commit, dispatch, state }) {
            try {
                commit("setPageSearchOption", {
                    pageNum: state.pageSearchOption.pageNum + 1,
                });
                const response = await axios.post(
                    `${apiURL}/api/v1/mypage/buys`,
                    state.pageSearchOption
                );
                if (response.data.code !== "S0000") {
                    throw response.data.code;
                } else {
                    commit(
                        "addMyPageShopHistoryDataMore",
                        response.data.data.list
                    );
                }
            } catch (code) {
                dispatch("popupProcessor/setSelectedPopupByCode", code, {
                    root: true,
                });
            }
        },
        async getMyPageBankRefundInfo({ commit, dispatch }, orderid) {
            try {
                const response = await axios.get(
                    `${apiURL}/api/v1/mypage/bankrefund?orderid=${orderid}`
                );
                if (response.data.code !== "S0000") {
                    dispatch("popupProcessor/setSelectedPopupByCode",
                        response.data.code,
                        { root: true }
                    );
                    return;
                } else {
                    commit("setBankRefundInfo", response.data);
                    commit("setBankRefundInfo", { possible: true });
                    commit("setBankRefundInfo", {
                        message:
                            "계정 도용으로 인한 사고 방지를 위해 <br> 환불계좌는​ 가입 시 본인 인증 정보와 일치해야만 합니다.",
                    });
                    commit("setBankRefundPayload", {
                        refundfee: response.data.data.refundfee,
                        refundprice: response.data.data.finalamount,
                        orderid: orderid,
                    });
                    commit("setMyPageShopHistoryDetail", {
                        elapseday: response.data.data.elapseday,
                        elapsemonth: response.data.data.elapsemonth,
                    });
                }
            } catch (e) {
                console.log(e);
            }
        },
        async updateBankRefund({ dispatch, state }) {
            try {
                const response = await axios.post(
                    `${apiURL}/api/v1/mypage/bankrefund`,
                    state.bankRefundPayload
                );
                if (response.data.code !== "S0000") {
                    dispatch("popupProcessor/setSelectedPopupByCode",
                        response.data.code,
                        { root: true }
                    );
                } else {
                    dispatch("popupProcessor/setSelectedPopupByPayload",
                        {
                            type: "yesOnly",
                            title: "알림",
                            text: "계좌환불이 접수되었습니다.",
                            payloadConfirmEvent() {
                                router.go(0);
                            },
                        },
                        { root: true }
                    );
                }
            } catch (e) {
                console.log(e);
            }
        },
        // 보유코드
        async getHoldingCodeData({ commit, dispatch, state }) {
            try {
                const response = await axios.post(
                    `${apiURL}/api/v1/mypage/itemcodes`,
                    state.pageSearchOption
                );
                if (response.data.code !== "S0000") {
                    throw response.data.code;
                }
                commit("setHoldingCodeData", response.data.data);
            } catch (code) {
                dispatch("popupProcessor/setSelectedPopupByCode", code, {
                    root: true,
                });
            }
        },
        async addHoldingCodeDataMore({ commit, dispatch, state }) {
            try {
                commit("setPageSearchOption", {
                    pageNum: state.pageSearchOption.pageNum + 1,
                });
                const response = await axios.post(
                    `${apiURL}/api/v1/mypage/itemcodes`,
                    state.pageSearchOption
                );
                if (response.data.code !== "S0000") {
                    throw response.data.code;
                }
                commit("addHoldingCodeDataMore", response.data.data.list);
            } catch (code) {
                dispatch("popupProcessor/setSelectedPopupByCode", code, {
                    root: true,
                });
            }
        },
        async getHoldingCodeDetail({ commit, dispatch, state }) {
            try {
                commit("setHoldingCodeDetailSearchOption", { pageNum: 1 });
                const response = await axios.post(
                    `${apiURL}/api/v1/mypage/itemcodes/detail`,
                    state.holdingCodeDetailSearchOption
                );
                if (response.data.code !== "S0000") {
                    throw response.data.code;
                } else {
                    commit("setHoldingCodeDetail", response.data.data);
                }
            } catch (code) {
                commit("setHoldingCodeDetail", code);
                dispatch("popupProcessor/setSelectedPopupByCode", code, {
                    root: true,
                });
            }
        },
        async addHoldingCodeDetailMore({ commit, dispatch, state }) {
            try {
                commit("setHoldingCodeDetailSearchOption", {
                    pageNum: state.holdingCodeDetailSearchOption.pageNum + 1,
                });
                const response = await axios.post(
                    `${apiURL}/api/v1/mypage/itemcodes/detail`,
                    state.holdingCodeDetailSearchOption
                );
                if (response.data.code !== "S0000") {
                    throw response.data.code;
                } else {
                    commit("addHoldingCodeDetailMore", response.data.data.list);
                }
            } catch (code) {
                dispatch("popupProcessor/setSelectedPopupByCode", code, {
                    root: true,
                });
            }
        },
        // 혜택관리 (마일리지 내역)
        async getMyBenefits({ commit, dispatch, state }) {
            try {
                const response = await axios.post(
                    `${apiURL}/api/v1/mypage/points`,
                    state.pageSearchOption
                );
                if (response.data.code !== "S0000") {
                    throw response.data.code;
                } else {
                    commit("setMyBenefits", response.data.data);
                }
            } catch (code) {
                dispatch("popupProcessor/setSelectedPopupByCode", code, {
                    root: true,
                });
            }
        },
        async addMyBenefitsMore({ commit, dispatch, state }) {
            commit("setPageSearchOption", {
                pageNum: state.pageSearchOption.pageNum + 1,
            });
            try {
                const response = await axios.post(
                    `${apiURL}/api/v1/mypage/points`,
                    state.pageSearchOption
                );
                if (response.data.code !== "S0000") {
                    throw response.data.code;
                } else {
                    commit("addMyBenefitsMore", response.data.data.list);
                }
            } catch (code) {
                dispatch("popupProcessor/setSelectedPopupByCode", code, {
                    root: true,
                });
            }
        },
        async getUserInfo({ commit , dispatch }) {
            try {
                const response = await axios.get(`${apiURL}/api/v1/user/infos`);
                if (response.data.code !== "S0000") {
                    throw response.data.code;
                } else {
                    commit("setUserInfo", response.data.data[0]);
                }
            } catch (code) {
                dispatch("popupProcessor/setSelectedPopupByCode", code, {
                    root: true,
                });
            }
        },
        async updateUserInfo({ state, dispatch }) {
            try {
                const response = await axios.put(
                    `${apiURL}/api/v1/user/infos`,
                    {
                        isemail: state.userInfo.emailflag,
                        isphone: state.userInfo.phoneflag,
                    }
                );
                if (response.data.code === "S0000") {
                    dispatch("popupProcessor/setSelectedPopupByPayload",
                        {
                            type: "yesOnly",
                            title: "알림",
                            text: "광고 수신 동의 정보가 변경 되었습니다.",
                            payloadConfirmEvent() {
                                dispatch("getUserInfo");
                            },
                        },
                        { root: true }
                    );
                } else {
                    throw response.data.code;
                }
            } catch (code) {
                dispatch("popupProcessor/setSelectedPopupByCode", code, {
                    root: true,
                });
            }
        },
        // 문의내역
        async getInquiryData({ commit, dispatch, state }) {
            try {
                const response = await axios.post(
                    `${apiURL}/api/v1/mypage/ask/list`,
                    {
                        ...state.pageSearchOption,
                        methodid: state.methodId,
                    }
                );
                if (response.data.code !== "S0000") {
                    throw response.data.code;
                } else {
                    commit("setInquiryData", response.data.data);
                }
            } catch (code) {
                dispatch("popupProcessor/setSelectedPopupByCode", code, {
                    root: true,
                });
            }
        },
        async addInquiryDataMore({ commit, dispatch, state }) {
            try {
                commit("setPageSearchOption", {
                    pageNum: state.pageSearchOption.pageNum + 1,
                });
                const response = await axios.post(
                    `${apiURL}/api/v1/mypage/ask/list`,
                    {
                        ...state.pageSearchOption,
                        methodid: state.methodId,
                    }
                );
                if (response.data.code !== "S0000") {
                    throw response.data.code;
                } else {
                    commit("addInquiryDataMore", response.data.data.list);
                }
            } catch (code) {
                dispatch("popupProcessor/setSelectedPopupByCode", code, {
                    root: true,
                });
            }
        },
        async getInquiryDetail({ commit, dispatch }, askid) {
            try {
                const response = await axios.get(
                    `${apiURL}/api/v1/mypage/ask/detail?askid=${askid}`
                );
                if (response.data.code !== "S0000") {
                    throw response.data.code;
                } else {
                    commit("setInquiryDetail", response.data.data);
                }
            } catch (code) {
                dispatch("popupProcessor/setSelectedPopupByCode", code, {
                    root: true,
                });
            }
        },
        // 회원 탈퇴 가능여부 체크
        async checkWithDrawPossible({ commit }) {
            try {
                const response = await axios.get(
                    `${apiURL}/api/v1/user/withdraw`
                );
                // 구매 확정 대기중 0건, 환불 진행중인 건이 0건이면 탈퇴가능 (userCancel.vue 에서 체크)
                if (response.data.data !== null) {
                    commit("setIsWithDrawPossible", response.data.data);
                }
            } catch (e) {
                console.error(e);
            }
        },
        // ************* 회원 탈퇴 ***************
        async deleteUserInfo({ dispatch, state }) {
            try {
                const encodedReason = encodeURIComponent(
                    [...state.withDrawReason].join("")
                );
                const response = await axios.delete(
                    `${apiURL}/api/v1/user/withdraw?reason=${encodedReason}`
                );
                if (response.data.code !== "S0000") {
                    dispatch("popupProcessor/setSelectedPopupByCode",
                        response.data.code,
                        { root: true }
                    );
                } else {
                    dispatch("popupProcessor/setSelectedPopupByPayload", {
						type: "yesOnly",
						title: "알림",
						text: "회원탈퇴가 완료 되었습니다.",
						payloadConfirmEvent() {
							window.open("/logout", "_self");
						},
						//  여전히 로그인 되어있는 상태임
					}, { root: true });
					gtmCustomEvent('accountDelete')
                }
            } catch (e) {
                console.error(e);
            }
        },
    },
    getters: {
        getPageSearchOption(state) {
            return state.pageSearchOption;
        },
        getPageSearchOptionDate(state) {
            return state.pageSearchOptionDate;
        },
        getmyPageShopHistoryData(state) {
            return state.myPageShopHistoryData;
        },
        getPageSearchOptionStatus(state) {
            return state.pageSearchOptionStatus;
        },
        getMyPageShopHistoryDetail(state) {
            return state.myPageShopHistoryDetail;
        },
        getMyPageBankRefundPayload(state) {
            return state.bankRefundPayload;
        },
        getMyPageBankRefundInfo(state) {
            return state.bankRefundInfo;
        },
        getHoldingCodeData(state) {
            return state.holdingCodeData;
        },
        getHoldingCodeDetail(state) {
            return state.holdingCodeDetail;
        },
        getHoldingCodeDetailSearchOption(state) {
            return state.holdingCodeDetailSearchOption;
        },
        getMyBenefits(state) {
            return state.myBenefits;
        },
        getUserInfo(state) {
            return state.userInfo;
        },
        getInquiryData(state) {
            return state.inquiryData;
        },
        getInquiryDetail(state) {
            return state.inquiryDetail;
        },
        getMethodId(state) {
            return state.methodId;
        },
        getIsWithDrawPossible(state) {
            return state.isWithDrawPossiple;
        },
    },
};
