<template>
	<appHeader />
	<router-view :key="$route.fullPath"></router-view>
	<appFooter />
	<layerPopup />
	<applicationPopup />
	<layerPopupOnBlackList />
</template>

<script setup>
import { onErrorCaptured, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import appHeader from "@/components/common/appHeader.vue";
import appFooter from "@/components/common/appFooter.vue";
import layerPopup from "@/components/common/layerPopup.vue";
import layerPopupOnBlackList from "@/components/common/layerPopupOnBlackList.vue";
import applicationPopup from "@/components/common/applicationPopup.vue";
const store = useStore();
const route = useRoute();
// 팝업 오픈 후 뒤로가기시 전체 잠기는 버그 수정
watch(route, () => {
	store.commit("startDataLoading");
	store.commit("popupProcessor/setActived", false);
	document.body.style.overflow = "auto";
	document.body.style.position = "relative";
});


onErrorCaptured((e) => {
	console.log(e);
	return false;
});

onMounted(() => {
	//구글 스크립트 태그 삽입
	const createdGTAGScript = document.createElement("script");
	const createdDataLayerScript = document.createElement("script");
	const createdWDSLIScript = document.createElement("script");
	const createdNoscript = document.createElement("noscript");
	const createdIFrame = document.createElement("iframe");
	const htmlTag = document.querySelector("html");
	createdGTAGScript.setAttribute(
		"src",
		"https://www.googletagmanager.com/gtag/js?id=G-7HQJKGNS05"
	);
	createdDataLayerScript.innerHTML = `
		window.dataLayer = window.dataLayer || [];
		function gtag(){dataLayer.push(arguments);}
		gtag('js', new Date());
		gtag('config', 'G-7HQJKGNS05');
	`;
	createdWDSLIScript.innerHTML = `
		<!-- Google Tag Manager -->
		(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
		})(window,document,'script','dataLayer','GTM-T2RV3QVR');
		<!-- End Google Tag Manager -->
	`;
	createdIFrame.src = "https://www.googletagmanager.com/ns.html?id=GTM-T2RV3QVR";
	createdIFrame.height = "0";
	createdIFrame.width = "0";
	createdIFrame.style.display = "none";
	createdIFrame.style.visibility = "hidden";
	createdNoscript.appendChild(createdIFrame);
	document.head.appendChild(createdGTAGScript);
	document.head.appendChild(createdDataLayerScript);
	document.head.appendChild(createdWDSLIScript);
	htmlTag.appendChild(createdNoscript);


});
</script>
<style>
@font-face {
	font-family: "pretendard-black";
	src: url("./assets/fonts/Pretendard-Black.woff2");
}

@font-face {
	font-family: "pretendard-bold";
	src: url("./assets/fonts/Pretendard-Bold.woff2");
}

@font-face {
	font-family: "pretendard-semibold";
	src: url("./assets/fonts/Pretendard-SemiBold.woff2");
}

@font-face {
	font-family: "pretendard-medium";
	src: url("./assets/fonts/Pretendard-Medium.woff2");
}

@font-face {
	font-family: "pretendard-regular";
	src: url("./assets/fonts/Pretendard-Regular.woff2");
}

@font-face {
	font-family: "pretendard-Light";
	src: url("./assets/fonts/Pretendard-Light.woff2");
}

@font-face {
	font-family: "EliceDXNeolli-BD";
	src: url("./assets/fonts/EliceDXNeolli-Bold.woff2");
}

@font-face {
	font-family: "EliceDXNeolli-L";
	src: url("./assets/fonts/EliceDXNeolli-Light.woff2");
}

@font-face {
	font-family: "EliceDXNeolli-M";
	src: url("./assets/fonts/EliceDXNeolli-Medium.woff2");
}

@font-face {
	font-family: "robotoBL";
	src: url("./assets/fonts/Roboto-Black.woff2");
}

@font-face {
	font-family: "robotoR";
	src: url("./assets/fonts/Roboto-Regular.woff2");
}

@font-face {
	font-family: "robotoBD";
	src: url("./assets/fonts/Roboto-Bold.woff2");
}

@font-face {
	font-family: "robotoM";
	src: url("./assets/fonts/Roboto-Medium.woff2");
}

/* Noto Sans 와 Noto Sans KR은 다른 폰트이기 때문에 "Noto Sans KR"을 사용합니다. */
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	color: #17191d;
	font-family: "Noto Sans KR";
}
/* *:focus{
	outline:none;
	-webkit-tap-highlight-color : transparent;
} */
/* 사파리 브라우저 폰트 깨지는 현상 수정에 도움이 됩니다. */
html {
	text-rendering: optimizeLegibility;
	overflow-y: scroll;
}

body {
	background: #f8f9fa;
	/* 사파리 더블탭 방지 테스트용 */
	touch-action: pan-y;
	width: 100%;
	/* max-width: 100%;
	min-height: 100vh;
	margin-right: -16px; */
}

img {
	user-select: none;
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
}

ul, li, ol {
	list-style: none;
}

a {
	text-decoration: none;
	color: #17191d;
}
a[x-apple-data-detectors] {
	color: inherit !important;
	text-decoration: none !important;
	font-size: inherit !important;
	font-family: inherit !important;
	font-weight: inherit !important;
	line-height: inherit !important;
}
a[href^="tel"]{
	color:inherit;
	text-decoration:none;
}
img {
	vertical-align: top;
}

input[type="text"][readonly] {
	background: #f8f9fa;
}
select[disabled] {
	background: #f8f9fa;
}

button {
	display: block;
	border: none;
	background: #fff;
	height: 50px;
	border-radius: 26px;
	margin: 0 auto;
	width: 100%;
}
button:hover{
	cursor: pointer;
}
button.gray-button,
button[disabled] {
	color: #888;
	background: #f1f2f3;
}

button.blue-background {
	border: 1px solid #1074f3;
	background-color: #1074f3;
	color: #fff;
}

button.pale-blue-background {
	background-color: #eef5fe;
	color: #1074f3;
}

button.blue-border {
	border: solid 1px #1074f3;
	color: #1074f3;
}

button.purple-border {
	border: solid 1px #6035f1;
	color: #6035f1;
}

button.purple-background {
	border: solid 1px #6035f1;
	color: #fff;
	background: #6035f1;
}

button.pale-purple-background {
	border: solid 1px #e5ebff;
	color: #6035f1;
	background: #e5ebff;
}

button.black-border {
	border: solid 1px #17191d;
	color: #17191d;
	background: #fff;
}

button > a {
	display: block;
	width: 100%;
	height: 100%;
	color: #fff;
}

#app {
	height: 100%;
	position: relative;
	overflow: hidden;
}

.page-layout {
	min-height: calc(100vh - 140px);
	margin: 80px 0px 0;
	background-color: #f8f9fa;
}

.page-layout--no-header {
	margin: 0px 0px 0;
	background-color: #f8f9fa;
}

.text-default {
	font-family: "Noto Sans KR";
	font-weight: normal;
}

.text-black {
	font-family: "Noto Sans KR";
	font-weight: 900;
}

.text-bold {
	font-family: "Noto Sans KR";
	font-weight: bold;
}

.text-medium {
	font-family: "Noto Sans KR";
	font-weight: 600;
}

.text-light {
	font-family: "Noto Sans KR";
	font-weight: 300;
}

.text-pretendard {
	font-family: "pretendard";
}

.text-elice-dx-neolli-bd {
	font-family: "EliceDXNeolli-BD";
}

.text-elice-dx-neolli-l {
	font-family: "EliceDXNeolli-L";
}

.text-elice-dx-neolli-m {
	font-family: "EliceDXNeolli-M";
}

.text-blue {
	color: #1074f3;
}

.text-purple {
	color: #6035f1;
}

.text-gray {
	color: #888;
}

.text-number {
	font-family: "robotoBL";
}

.text-number-R {
	font-family: "robotoR";
}

.text-number-M {
	font-family: "robotoM";
}

.text-break {
	display: none;
}

.on-cursor:hover {
	cursor:pointer;
}

/*스켈레톤 UI*/
*.skeleton, .skeleton{
	background:linear-gradient(90deg, #eeeeee 10%, #dfdfdf 30%, #eeeeee 40%);
	background-size: 200% 200%;
	animation: gradient 1s infinite ease-in-out;
}

@keyframes gradient{
	0%{
		background-position:100% 0%;
	}
	100%{
		background-position:-100% 0%;
	}
}

@media screen and (min-width: 320px) {
	.text-break {
		display: none;
	}
}

@media screen and (min-width: 375px) {
	.text-break {
		display: block;
	}
}

@media screen and (min-width: 430px) {
	.page-layout {
		margin: 80px 0px 0;
	}

	.page-layout--no-header {
		margin: 0px 0px 0;
	}
}

@media screen and (min-width: 1200px) {
	.page-layout {
		margin: 120px auto 0;
		height: auto;
		position: relative;
	}

	.page-layout--no-header {
		margin: 0 auto;
	}

	.text-break {
		display: block;
	}
}
</style>