import axios from "axios";
const apiURL = process.env.VUE_APP_API 
export const shopMain = {
	namespaced:true,
	state:{
		shopMainData:[],
		defaultCSS:{
			videoTape:{
				sliderHeight: "90px",
				slideWidth:284,
				slideHeight:90,
				slideGap:10,
				slideRadius:12,
				autoPlayActive: true,
				showSliderButton:true,
				showBullets:true,
				bulletSize: "8px",
				bulletWrapperCSS:'bottom:-16px;left:50%;transform:translateX(-50%);border-radius:20px;gap:6px;',
			},
			shopList:{
				decorativeImg:require('@/assets/img/ic_plug_type_new@2x.png'),
				decorativeImgSize:'width:42px; height:42px;',
				numberOfMaxCardVisible:4,
				cardWidth:100,
				cardHeight:160, //cardWidth로부터 60% 증가
				cardImgHeight:120, // cardWidth로부터 20% 증가
				cardGap:12,
				leftPadding:12,
				rightPadding:12,
				borderRadius:4,
			},
			allPromotion:{
				nextSlideVisiblePercentage:10, // 윈도우 width 대비 퍼센테이지 = 슬라이더 1개 크기
				slideWidthProportion:1080, //width:height의 비율입니다. (크기 아님)
				slideHeightProportion:360,
				slideWidth:360,
				slideHeight:120,
				slideGap:6,
				slideRadius:8,
				showSliderButton:false,
				showBullets:true,
				autoPlayActive: true,
				topButtonWrapperCSS:'height:24px; margin-bottom:16px;',
				topButtonCSS:'padding:0 20px; height:24px; line-height:24px; border-radius:16px;',
				bulletSize: 8,
				bulletGap:6,
				bulletWrapperCSS:'left:50%; margin:14px 0 0 0; transform:translateX(-50%);',
			},
			allGames:{
				numberOfMaxCardVisible:4,
				cardWidth:100,
				cardHeight:150, //cardWidth로부터 60% 증가
				cardImgHeight:100,
				cardGap:12,
				leftPadding:12,
				rightPadding:12,
				borderRadius:4,
			}
		},
	},
	
	mutations:{
		initSliderCSS(state){
			state.shopMainData.forEach(item =>{
				const cssType = item.cssType
				Object.assign(item, state.defaultCSS[cssType])
			})
		},
		updateSliderCSS(state, payload){
			for(let i = 0; i<state.shopMainData.length; i++){
				if(state.shopMainData[i].cssType === payload.cssType){
					Object.assign(state.shopMainData[i], payload)
				}
				else if(!state.shopMainData[i].cssType){
					continue
				}
			}
		},
		setShopMainData(state, payload){
			state.shopMainData = payload
		},
	},
	actions:{
		async getShopMainData({dispatch, commit, state}){
			const defaultCSS = state.defaultCSS
			const bannerTypeBySetID = {
				1:{
					bannerComp:'videoTape',
					bannerClass:'shop-main__top-slider-wrapper',
					cssType:'videoTape'
				},
				2:{
					bannerComp:'oneBanner',
					bannerClass:'shop-main__top-slider-wrapper'
				},
				3:{
					bannerComp:'oneBanner',
					bannerClass:'shop-main__top-slider-wrapper'
				},
				4:{
					bannerComp:'bleeding',
					bannerClass:'shop-main__bleeding-slider-wrapper content-area',
					cssType:'allPromotion'
				},
				5:{
					bannerComp:'card',
					bannerClass:'shop-main__card-slider-wrapper content-area',
					cssType:'shopList'
				},
				6:{
					bannerComp:'card',
					bannerClass:'shop-main__card-slider-wrapper content-area',
					cssType:'shopList'
				},
				7:{
					bannerComp:'card',
					bannerClass:'shop-main__card-slider-wrapper content-area',
					cssType:'shopList'
				},
				8:{
					bannerComp:'card',
					bannerClass:'shop-main__card-slider-wrapper content-area',
					cssType:'allGames'
				},
			}
			const getLayoutArrays = async () =>{
				try{
					const response = await axios.get(`${apiURL}/api/v1/shop/layout`)
					if(response.data.code !== 'S0000'){
						throw response.data.code 
					}
					return {
						title:response.data.data.map(item => item.title),
						orderNo:response.data.data.map(item => item.orderno),
						setID:response.data.data.map(item => item.setid),
						list:response.data.data.map(item => axios.get(`${apiURL}/api/v1/shop/layout/banners?setid=${item.setid}`))
					}
				}
				catch(code){
					dispatch("popupProcessor/setSelectedPopupByCode", code, {
						root: true,
					});
					return false
				}
			}
			const getDataByPageLayout = async (layoutArrays) =>{
				if(!layoutArrays){
					return false
				}
				try{
					const dataList = []
					const response = await Promise.all(layoutArrays.list)
					for(let i = 0; i<response.length; i++){
						if(response[i].data.code !== 'S0000' || response[i].data.data.length === 0){
							throw response[i].data.code
						}
						const setID = layoutArrays.setID[i]
						const slideData = response[i].data.data
						const obj = {
							bannerComp:bannerTypeBySetID[setID].bannerComp,
							bannerClass:bannerTypeBySetID[setID].bannerClass,
							slideData:slideData,
							transition:300,
							autoPlayDelay:7000,
							sliderWidth: "100%",
							imageLength:slideData.length || 0,
							nextFirstImage:slideData.length !== 0 ? slideData[0].bannerimg : null,
							prevLastImageIndex:slideData.length !== 0 ? slideData.length - 1 : 0,
							prevLastImage:slideData.length !== 0 ? slideData[slideData.length - 1].bannerimg : null,
							orderNO:layoutArrays.orderNo[i], 
							title:layoutArrays.title[i], 
							cssType:bannerTypeBySetID[setID].cssType,
							setID,
							...defaultCSS[bannerTypeBySetID[setID].cssType]
						}
						dataList.push(obj)	
					}
					commit('setShopMainData', dataList)
					return true
				}
				catch(code){
					dispatch("popupProcessor/setSelectedPopupByCode", code, {
						root: true,
					});
					return false
				}
			}
			const layoutArrays = await getLayoutArrays()
			const isDataValid = await getDataByPageLayout(layoutArrays)
			let finishDataLoadingID = null
			 // 데이터 호출여부에 따라 반환된 boolean 값을 받아서 true일 경우 스켈레톤 UI를 걷어내고 화면을 표시합니다.
			if(isDataValid){
				finishDataLoadingID = setTimeout(()=>{
					commit('finishDataLoading', true, {root:true})
					clearTimeout(finishDataLoadingID)
				}, 600)
			}
		},
		async getGameCount({dispatch}){
			try{
				const response = await axios.get(`${apiURL}/api/v1/games/count`)
				if(response.data.code !== 'S0000'){
					throw response.data.code
				}
				return response.data.data
			}
			catch(code){
				dispatch('popupProcessor/setSelectedPopupByCode', code, {root:true})
			}
		}
	},
	getters:{
		getShopMainData(state){
			const accendingOrderNO = (a, b) =>{
				return a.orderNO - b.orderNO
			}
			const sortedArray = state.shopMainData.sort(accendingOrderNO)
			return sortedArray
		}	
	},
}