<template>
	<div v-if="isPopupActived" class="intro-application__popup-cover" :class="{'low-height':isHeightLow}">
		<div class="intro-application">
			<h1>제휴문의 <img src="@/assets/img/btn_close_w-black@2x.png" alt="close" @click="toggleApplication(false)">
			</h1>
			<section>
				<div class="intro-application__wrapper">
					<p>
						<strong>게임브릿지</strong>는 <strong>게임 개발사의 좋은 제안</strong>을 기다립니다.<br />
						게임 웹샵에 관한 문의사항이 있으시면
						<span class="block-in-mobile"><strong>언제든지 문의</strong>를 남겨주세요.</span>
						<span><strong>접수된 내용</strong>을 <strong>검토 후 회신</strong> 드리겠습니다.</span>
					</p>
					<div class="intro-application__form-wrapper">
						<dl>
							<dt>업체명*</dt>
							<dd>
								<input type="text" v-model="applicationForm.cpcom" maxlength="20" placeholder="2 ~ 20자">
							</dd>
						</dl>
						<dl>
							<dt>운영게임명*</dt>
							<dd>
								<input type="text" v-model="applicationForm.opgame" maxlength="100" placeholder="2 ~ 100자">
							</dd>
						</dl>
						<dl>
							<dt>담당자명*</dt>
							<dd>
								<input type="text" v-model="applicationForm.cpname" maxlength="6" placeholder="2 ~ 6자">
							</dd>
						</dl>
						<dl>
							<dt>전화번호</dt>
							<dd>
								<input type="text" v-model="applicationForm.phone" @input="checkValidNumber('phone')">
							</dd>
						</dl>
						<dl>
							<dt>휴대폰번호</dt>
							<dd>
								<input type="text" v-model="applicationForm.cellphone" @input="checkValidNumber('cellphone')">
							</dd>
						</dl>
						<dl>
							<dt>이메일</dt>
							<dd>
								<input type="text" v-model="applicationForm.email">
							</dd>
						</dl>
						<dl class="intro-application__inquiry">
							<dt>문의내용</dt>
							<dd>
								<textarea v-model="applicationForm.reqnoe" maxlength="200" placeholder="최대 200자"></textarea>
							</dd>
						</dl>
					</div>
					<button @click="reqPartnership">제휴문의 접수</button>
				</div>
			</section>
		</div>
	</div>
</template>

<script setup>
import axios from 'axios'
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import {gtmCustomEvent} from '@/util/global'
const store = useStore()
const isPopupActived = ref(computed(() => store.getters.getIsApplicationPopupActived))
const isHeightLow = ref(window.innerHeight <= 800)
const applicationForm = ref({
	cellphone: '',
	cpcom: '',
	cpname: '',
	email: '',
	opgame: '',
	phone: '',
	reqnoe: ''
})
const initApplicationForm = () => {
	applicationForm.value.cellphone = ''
	applicationForm.value.cpcom = ''
	applicationForm.value.cpname = ''
	applicationForm.value.email = ''
	applicationForm.value.opgame = ''
	applicationForm.value.phone = ''
	applicationForm.value.reqnoe = ''
}
const toggleApplication = (boolean) => {
	if(!boolean){
		initApplicationForm()
	}
	store.commit('toggleApplicationPopup', boolean)
}

//유효성 체크 후에 다시 정리된 파라미터
const getTrimmedParams = () =>{
	const params = {
		cpcom:applicationForm.value.cpcom,
		cpname:applicationForm.value.cpname,
		opgame:applicationForm.value.opgame,
		reqnoe:applicationForm.value.reqnoe
	}
	if(applicationForm.value.email !== ''){
		params.email = applicationForm.value.email
	}
	if(applicationForm.value.phone !== ''){
		params.phone = applicationForm.value.phone
	}
	if(applicationForm.value.cellphone !== ''){
		params.cellphone = applicationForm.value.cellphone
	}
	return params
}
const checkValidity = () => {
	const checkEmailValidity = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i
	applicationForm.value.cpcom = applicationForm.value.cpcom.trim()
	applicationForm.value.cpname = applicationForm.value.cpname.trim()
	applicationForm.value.opgame = applicationForm.value.opgame.trim()
	applicationForm.value.email = applicationForm.value.email.trim()
	applicationForm.value.phone = applicationForm.value.phone.trim()
	applicationForm.value.cellphone = applicationForm.value.cellphone.trim()
	//필수 값이 모두 공란인 경우 
	const isApplicantsInfoValid = applicationForm.value.cpcom !== '' && 	
	applicationForm.value.cpname !== '' && 
	applicationForm.value.opgame !== ''
	

	const isApplicantsInfoLengthValid = applicationForm.value.cpcom.length >= 2 &&
	applicationForm.value.cpname.length >= 2 &&
	applicationForm.value.opgame.length >= 2


	//이메일 유효성을 통과 못했으며 다른 연락처가 공란인 경우 
	const isApplicantsEmailValid = checkEmailValidity.test(applicationForm.value.email) || applicationForm.value.email === ''
	//모든 연락처가 공란인 경우
	const isApplicantsContactInvalid = applicationForm.value.email === '' && applicationForm.value.phone === '' && applicationForm.value.cellphone === ''
	
	if (!isApplicantsInfoValid) {
		store.dispatch('popupProcessor/setSelectedPopupByPayload', {
			type: 'yesOnly',
			title: '알림',
			text: '필수 항목을 다시 확인해주세요.',
			scrollRelease:'no'
		})
		return false
	}
	else if (!isApplicantsInfoLengthValid){
		store.dispatch('popupProcessor/setSelectedPopupByPayload', {
			type: 'yesOnly',
			title: '알림',
			text: '각 필수항목의 최소 글자 수를 확인하세요.',
			scrollRelease:'no'
		})
		return false
	}
	else if (isApplicantsContactInvalid) {
		store.dispatch('popupProcessor/setSelectedPopupByPayload', {
			type: 'yesOnly',
			title: '알림',
			text: '연락처를 한 가지 이상 입력해주세요.',
			scrollRelease:'no'
		})
		return false
	}
	else if(!isApplicantsEmailValid){
		store.dispatch('popupProcessor/setSelectedPopupByPayload', {
			type: 'yesOnly',
			title: '알림',
			text: '이메일 형식을 확인해주세요.',
			scrollRelease:'no'
		})
		return false
	}

	return true
}
const checkValidNumber = (valueName) =>{
	applicationForm.value[valueName] = applicationForm.value[valueName].replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
	const stringifiedNumber = applicationForm.value[valueName].toString() 
	if(stringifiedNumber.length > 11){
		applicationForm.value[valueName] = stringifiedNumber.slice(0, 11)
	}
}
const reqPartnership = async () => {
	if (!checkValidity()) {
		return
	}
	try {
		const params = getTrimmedParams()
		const apiURL = process.env.VUE_APP_API
		const response = await axios.post(`${apiURL}/api/v1/user/partner`, params)
		if (response.data.code !== 'S0000') {
			store.dispatch('popupProcessor/setSelectedPopupByCode', response.data.code)
			return
		}
		else {
			initApplicationForm()
			store.dispatch('popupProcessor/setSelectedPopupByPayload', {
				type: 'yesOnly',
				title: '알림',
				text: '성공적으로 접수되었습니다.',
				scrollRelease:'afterConfirm',
			})
			//구글 태그매니저 전용 코드 : 파트너제안서 제출 성공
			gtmCustomEvent('partnershipSubmitSuccess')
			store.commit('completeApplication')
		}
	}
	catch (e) {
		console.log(e)
	}
}

</script>

<style scoped>
/*제휴문의 */
.intro-application__popup-cover {
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	position: fixed;
	z-index: 2;
}
.intro-application__popup-cover.low-height{
	overflow-y:scroll;
}
.intro-application {
	width: 100%;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	background: #f8f9fa;
	padding-bottom: 36px;
}

.intro-application h1 {
	background: #f0f5f9;
	height: 52px;
	font-size: 24px;
	text-align: center;
	color: #17191d;
	line-height: 52px;
	border: 1px solid #d7d8d8;
	position: relative;
}

.intro-application h1 img {
	width: 24px;
	height: 24px;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	position: absolute;
}

.intro-application section {
	padding: 0 24px 24px;
}

.intro-application section p {
	font-size: 16px;
	letter-spacing: -0.8px;
	text-align: center;
	color: #17191d;
	padding: 30px;
}

.intro-application section p span {
	display: block;
}

.intro-application section p .block-in-mobile {
	display: block;
}

.intro-application .intro-application__form-wrapper {
	padding: 16px 16px 24px;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	border: solid 1px #d8d8d8;
	gap: 24px;

}

.intro-application .intro-application__form-wrapper dl dt {
	font-size: 16px;
	font-weight: bold;
	line-height: 0.94;
	letter-spacing: -0.8px;
	color: #17191d;
}

.intro-application .intro-application__form-wrapper dl dd input {
	display: block;
	padding: 0 10px;
	border-radius: 4px;
	border: solid 1px #d8d8d8;
	background-color: #fff;
	width: 100%;
	height: 32px;
	margin-top: 10px;
}
.intro-application .intro-application__form-wrapper dl dd input::placeholder, 
.intro-application .intro-application__form-wrapper dl dd textarea::placeholder{
	color:#d8d8d8;
}
.intro-application .intro-application__form-wrapper dl dd textarea {
	padding: 10px;
	border-radius: 4px;
	border: solid 1px #d8d8d8;
	background-color: #fff;
	width: 100%;
	margin-top: 10px;
	height: 128px;
}

.intro-application button {
	display: block;
	width: 120px;
	height: 32px;
	margin: 24px auto;
	border-radius: 8px;
	border: solid 1px #1074f3;
	background-color: #eef5fe;
	font-size: 16px;
	letter-spacing: -0.8px;
	color: #1074f3;
}

@media screen and (min-width: 760px) {
	.intro-application {
		width: 640px;
		padding-bottom: 0;
		top: 100px;
	}

	.intro-application h1 {
		background: #f8f9fa;
		height: 44px;
		font-size: 18px;
		text-align: center;
		color: #17191d;
		line-height: 44px;
		border: 1px solid #d7d8d8;
	}

	.intro-application section {
		padding: 10px;
	}

	.intro-application .intro-application__wrapper {
		background: #fff;
		border: 1px solid #d8d8d8;
		padding: 0 50px;
	}

	.intro-application .intro-application__wrapper p {
		padding: 26px 0 40px;
		font-size: 18px;
	}

	.intro-application .intro-application__wrapper button {
		margin: 24px auto;
	}

	.intro-application .intro-application__wrapper p .block-in-mobile {
		display: inline;
	}

	.intro-application .intro-application__form-wrapper {
		padding: 0;
		border: solid 1px #d8d8d8;
		display: flex;
		flex-direction: column;
		gap: 0;
		background: #fff;
		border-bottom: none;
	}

	.intro-application .intro-application__form-wrapper dl {
		display: flex;
		height: 44px;
		border-bottom: 1px solid #d8d8d8;
	}

	.intro-application .intro-application__form-wrapper dl dt {
		width: 88px;
		background: #f0f5f9;
		text-align: center;
		line-height: 44px;
		font-size: 13px;
		font-weight: 500;
		letter-spacing: -0.65px;
		color: #17191d;
	}

	.intro-application .intro-application__form-wrapper dl dd {
		width: calc(100% - 88px);
		padding: 6px 12px;
	}

	.intro-application .intro-application__form-wrapper dl dd input {
		margin: 0;
		width: 100%;
		border: 1px solid #f0f5f8;
	}

	.intro-application .intro-application__form-wrapper dl.intro-application__inquiry {
		height: 128px;
	}

	.intro-application .intro-application__form-wrapper dl.intro-application__inquiry dd {
		width: calc(100% - 88px);
	}

	.intro-application .intro-application__form-wrapper dl.intro-application__inquiry dd textarea {
		width: 100%;
		height: 100%;
		margin: 0;
		border: 1px solid #f0f5f8;
		resize: none;
	}
}
</style>