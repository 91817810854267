/* 	
	popupOptions 에 정의된 명세 중 resultCD가 일치하는 명세를 selectedPopup 에 업데이트 합니다.
	popupOptions[resuldCD].popup: <boolean> true 면 selectedPopup, false: 기타 다른 이벤트 발생
	selectedPopup.type : <string> 'yesOnly' (버튼 하나만 있는 경우) | 'yesOrNoSelect' (긍정 부정 (예, 아니오 등) 버튼 2개) | 'waiting' 로딩 전용. 버튼 없음   
 	selectedPopup.title : <string> 기본값: '알림', 팝업 제목 
	selectedPopup.text : <string> 팝업 내용
	selectedPopup.buttonConfirmText : <string> 긍정반응 버튼이름
	selectedPopup.buttoncancelText : <string> 부정반응 버튼이름
	selectedPopup.buttonConfirmClass : <string> 긍정반응 버튼 추가 클래스 (구글 태그매니저 감지할때 사용)
	selectedPopup.popupActived : <boolean> 팝업 열기 / 닫기 
	selectedPopup.scrollRelease : <string | null>  'no' 스크롤 잠금 해제 안됨 | 'afterConfirm' 확인 버튼 클릭 후 스크롤 잠금 해제 | null 확인 버튼 클릭 후 스크롤 
	popupOptions.payloadConfirmEvent: 커스텀이벤트를 정의하고 사용할 수 있습니다.
	popupOptions.confirmEvent, popupOptions.cancelEvent 명시되어 있지 않으면 팝업 닫힘
*/
import popupOptions from '@/util/popupOptions'
import {blockBodyScrollEvents, releaseBodyScroll} from '@/util/global'

export const popupProcessor = {
	namespaced:true, 
	state:{
		isAdditionalPopupOn:false,
		selectedPopup:{
			popupActived:false,
			type:'yesOnly',
			title:'알림',
			text:'',
			nextEvent:null,
			buttonConfirmText:'확인',
			buttonCancelText:'취소',
			buttonConfirmClass:null,
			scrollRelease:null
		},
		redirectionURL:'',
	},
	mutations:{
		setActived(state, booleanVal){
			state.selectedPopup.popupActived = booleanVal
		}, 
		executeConfirmEvent(state){
			const scrollRelease = state.selectedPopup.scrollRelease
			if(scrollRelease === 'no'){
				state.selectedPopup.popupActived = false
				state.selectedPopup.scrollRelease = null
				return 
			}
			if(popupOptions.payloadConfirmEvent || scrollRelease === 'afterConfirm'){
				state.selectedPopup.popupActived = false
				state.selectedPopup.scrollRelease = null
				popupOptions.payloadConfirmEvent ? popupOptions.payloadConfirmEvent() : undefined
				releaseBodyScroll()
				return 
			}
			if( popupOptions.optionsByCode[state.selectedPopup.code]){
				const confirmEvent = popupOptions.optionsByCode[state.selectedPopup.code].confirmEvent || null
				confirmEvent ? confirmEvent() : undefined
				state.selectedPopup.popupActived = false
			}
			else{
				state.selectedPopup.popupActived = false
			}
			releaseBodyScroll()
		},
		executeCancelEvent(state){
			const scrollRelease = state.selectedPopup.scrollRelease
			if(scrollRelease === 'no'){
				state.selectedPopup.popupActived = false
				state.selectedPopup.scrollRelease = null
				return 
			}
			if(state.selectedPopup.cancelEvent){
				state.selectedPopup.cancelEvent()
				state.selectedPopup.popupActived = false
			}
			else{
				state.selectedPopup.popupActived = false
			}
			releaseBodyScroll()
		},
		setIsAdditionalPopupOn(state){
			state.isAdditionalPopupOn = !state.isAdditionalPopupOn
		},
		setRedirect(state, url){
			state.redirectionURL = url
		},
		initSelectedPopup(state){
			state.selectedPopup.type = 'yesOnly'
			state.selectedPopup.popupActived = false
			state.selectedPopup.title ='알림'
			state.selectedPopup.text = ''
			state.selectedPopup.nextEvent = null
			state.selectedPopup.buttonConfirmText = '확인'
			state.selectedPopup.buttonCancelText ='취소'
			state.selectedPopup.buttonConfirmClass = null
			state.selectedPopup.scrollRelease = null
		},
	},
	actions:{
		setSelectedPopupByCode({state, commit}, payload){
			//payload 는 객체 (data) 아니면 code 로 받습니다.
			const payloadCode = payload?.code ? payload.code : payload
			//팝업 초기화
			commit('initSelectedPopup')
			popupOptions.payloadConfirmEvent = null
			if(!payloadCode || popupOptions.optionsByCode[payloadCode] === undefined ){
				blockBodyScrollEvents()
				state.selectedPopup.popupActived = true
				state.selectedPopup.text = '알 수 없는 에러입니다. 새로고침 또는 확인 버튼을 눌러 주세요.'
				popupOptions.payloadConfirmEvent = ()=>{window.open('/', '_self')}
				return
			}
			if(!popupOptions.optionsByCode[payloadCode].popup){ //ES1000 인 경우 
				return 
			}
			else if(popupOptions.optionsByCode[payloadCode].popup){
				blockBodyScrollEvents()
				state.selectedPopup.popupActived = true
				state.selectedPopup.code = payloadCode
				Object.assign(state.selectedPopup, popupOptions.optionsByCode[payloadCode])
				popupOptions.payloadConfirmEvent = payload.payloadConfirmEvent || null
			}
		},
		setSelectedPopupByPayload({state, commit}, payload){
			//팝업 초기화
			commit('initSelectedPopup')
			state.selectedPopup.popupActived = true
			Object.assign(state.selectedPopup, payload)
			popupOptions.payloadConfirmEvent = payload.payloadConfirmEvent || null
			if(state.selectedPopup.type === 'waiting' || payload.scrollRelease === 'no' || payload.scrollRelease === 'afterConfirm' ){
				return 
			}
			blockBodyScrollEvents()
		},
	},
	getters:{ 
		getSelectedPopup(state){
			return {
				popupActived:state.selectedPopup.popupActived,
				title:state.selectedPopup.title,
				text:state.selectedPopup.text,
				type:state.selectedPopup.type,
				buttonConfirmText:state.selectedPopup.buttonConfirmText,
				buttonCancelText:state.selectedPopup.buttonCancelText,
				buttonConfirmClass:state.selectedPopup.buttonConfirmClass,
			}
		},
		getIsAdditionalPopupOn(state){
			return state.isAdditionalPopupOn
		}
	}
}