import store from "@/store";

const clearLocalStorageExceptSpecificKey = (keyToExclude) =>{
	const localStorageKeys = Object.keys(localStorage);
	// 각 키를 확인하면서 특정 값인 경우를 제외하고 제거합니다.
	localStorageKeys.forEach(item => {
		if (item !== keyToExclude) {
			localStorage.removeItem(item);
		}
	});
}

export default [
	{
        // 서비스 소개
        path: "/shopMain",
        name: "ShopMain",
        component: () => import("@/components/shopMain"), // import("@/components/homePage"),
        meta: {
            title: "GameBridge | 게임브릿지",
        },
		async beforeEnter(to, from, next){
			next("/shop?game=lostcity"); //무조건 로스트시티 샵으로 이동하기
			// gameCount가 0이면 샵으로 이동되지 않는 부분을 주의해야 합니다.
			// const gameCount = await store.dispatch('shopMain/getGameCount')
			// if(gameCount > 1 ){
			// 	next()
			// }
			// else if(gameCount === 1) {
			// 	next("/shop?game=lostcity");
			// }
		}
    },
    {
        path: "/shop",
        name: "ShopPage",
        component: () => import("@/components/shop/shopPage"),
        meta: {
            title: "게임브릿지 | 로스트시티 제로 샵",
        },
		beforeEnter:(to, from, next)=>{
			if(from.name === undefined || from.name === 'ShopDetail' ){
				next()
			}
			else{
				clearLocalStorageExceptSpecificKey('menuid')
				store.commit('shopPage/setItemQuantity', 1)
				next()
			}
		}
    },
    {
        path: "/shop/detail",
        name: "ShopDetail",
        component: () => import("@/components/shop/shopDetail"),
        meta: {
            title: "로스트시티 제로 상품상세",
        },
		beforeEnter:(to, from, next)=>{
			//url 정보 저장: 정체불명의 NaN이 나오는 현상이 있어 검사합니다.
			store.dispatch('shopPage/getURLs', {previousURL:from.fullPath, currentURL:to.fullPath})
			if(from.name === undefined || from.name === 'ShopPage' || from.name === 'AccountLogin'){
				next()
			}
			else{
				clearLocalStorageExceptSpecificKey('menuid')
				next()
			}
		}
    },
    {
        path: "/shop/purchase",
        name: "ShopPurchase",
        component: () => import("@/components/shop/shopPurchase"),
        meta: {
            title: "로스트시티 제로 결제진행",
        },
        beforeEnter: async (to, from, next) => {
			if(!localStorage.getItem('itemTitle')){
				next("/");
			}
            else if (from.name === "ShopDetail" || from.name === 'ShopDrop' || from.name === 'AccountLogin') {
				next();
            } 
			else if(from.name === undefined){ //새로고침 시 
				const accesskey = localStorage.getItem('itemAccessKey')
				const param = {
					gameid: parseInt(localStorage.getItem('gameid')),
					itemid: parseInt(localStorage.getItem('itemid')),
					itemcnt: parseInt(localStorage.getItem('quantity')),
				}
				if(accesskey){
					param.accesskey = accesskey
				}
				await store.dispatch("shopPage/getOrderRequestResponse", param)
				next();
			}
			else {
				next("/");
            }
        },
    },
    {
        path: "/shop/payloading",
        name: "ShopPayLoading",
        component: () => import("@/components/shop/shopPayLoading"),
    },
    {
        path: "/shop/complete",
        name: "ShopComplete",
        component: () => import("@/components/shop/shopComplete"),
        meta: {
            title: "로스트시티 제로 결제완료",
        },
        beforeEnter: (to, from, next) => {
            if (from.name !== "ShopPurchase") {
                next("/");
            } else {
                next();
            }
        },
    },
	{
        path: "/shop/inspecting",
        name: "ShopInspecting",
        component: () => import("@/components/shop/shopInspecting"),
        meta: {
            title: "운영점검",
        },
    },
	{
        path: "/shop/closed",
        name: "ShopClosed",
        component: () => import("@/components/shop/shopClosed"),
        meta: {
            title: "운영종료",
        },
    },
	{
		path: "/shop/item-invalid",
		name:'ShopItemInvalid',
		component: () => import("@/components/shop/shopItemInvalid"),
	},
    {
        path: "/shop/drop",
        name: "ShopDrop",
        component: () => import("@/components/shop/shopDrop"),
    },
	{
		path:'/shop/payletter_drop',
		name:'RedirectDrop',
		component: () => import("@/components/shop/payletterDrop"),
	}
];
