/*
 *로그인, 블랙리스트 여부 등, 등 각종 공용 함수를 정의해 둔 store입니다. 
*/

import axios from "axios";
import { createStore } from "vuex";
import { popupProcessor } from "@/store/modules/popupProcessor.js";
import { accountPage } from "@/store/modules/accountPage.js";
import { shopMain } from "@/store/modules/shopMain.js";
import { shopPage } from "@/store/modules/shopPage.js";
import { myPage } from "@/store/modules/myPage.js";
import { inquiry } from "@/store/modules/inquiry.js";
import {blockBodyScrollEvents, releaseBodyScroll} from '@/util/global'
const apiURL = process.env.VUE_APP_API;
export default new createStore({
    modules: {
        accountPage,
		shopMain,
        shopPage,
        popupProcessor,
        myPage,
        inquiry,
    },
    state: {
        loginStatus: {
            code: "",
            isLogin: false,
            memname: "",
            mempoint: 0,
            codecnt: 0,
            nowdate: "",
        },
        isGlobalNavActived: false,
        appData: {
            appName: "게임브릿지",
        },
        isAppicationPopupActived: false,
        isDataLoadingFinished: false,
        isUserOnBlackList: false,
        blackListDetails: {},
        savedScrollPosition: {
            conditional: false,
            left: 0,
            top: 0,
        },
        isPaymentTableOpened: false,
    },
    mutations: {
        setLoginStatus(state, payload) {
            state.loginStatus.isLogin = payload.isLogin;
            state.loginStatus.code = payload.code;
            state.loginStatus.nowdate = payload.nowdate;
        },
        setUserPoints(state, payload) {
            state.loginStatus.memname = payload.memname;
            state.loginStatus.mempoint = payload.mempoint;
            state.loginStatus.codecnt = payload.codecnt;
            state.loginStatus.monthend = payload.monthend;
            state.loginStatus.delpoint = payload.delpoint;
            state.loginStatus.nowdate = payload.nowdate;
        },
        initLoginStatus(state) {
            state.loginStatus = {
                code: "",
                isLogin: false,
                memname: "",
                mempoint: 0,
                monthend: "",
                delpoint: "",
                codecnt: 0,
            };
        },
        initBlackList(state) {
            state.isUserOnBlackList = false;
            state.blackListDetails = {};
        },
        setBlackList(state, payload) {
            state.isUserOnBlackList = true;
            state.blackListDetails = payload;
        },
        toggleGlobalNavigation(state, boolean) {
            boolean !== undefined
                ? (state.isGlobalNavActived = boolean)
                : (state.isGlobalNavActived = !state.isGlobalNavActived);
            state.isGlobalNavActived
                ? (document.body.style.overflow = "hidden")
                : (document.body.style.overflow = "auto");
        },
        toggleApplicationPopup(state, boolean) {
            state.isAppicationPopupActived = boolean;
            state.isAppicationPopupActived ? blockBodyScrollEvents() : releaseBodyScroll()
        },
		completeApplication(state){
			state.isAppicationPopupActived = false
		},
        deductMileage(state, mileageValue) {
            const mempoint = state.loginStatus.mempoint;
            state.loginStatus.mempoint = mempoint - mileageValue;
        },
        startDataLoading(state) {
            state.isDataLoadingFinished = false;
        },
        finishDataLoading(state) {
            state.isDataLoadingFinished = true;
        },
        setSavedScrollPosition(state, payload) {
            state.savedScrollPosition = {
                ...state.savedScrollPosition,
                ...payload,
            };
        },
        setIsPaymentTableOpened(state) {
            state.isPaymentTableOpened = !state.isPaymentTableOpened;
			state.isPaymentTableOpened ? blockBodyScrollEvents() : releaseBodyScroll()
        },
    },
    actions: {
        async checkBlackListOnly({ commit, dispatch }) {
            const response = await axios.get(`${apiURL}/api/v1/user/black`);
            if (response.data.code === "S0000") {
                commit("setBlackList", response.data.data);
            } else {
                dispatch(
                    "popupProcessor/setSelectedPopupByCode",
                    response.data.code,
                    { root: true }
                );
            }
        },
        async getLoginStatus({ commit , dispatch}) {
            const userStatus = {
                loginCode: "",
                isLogin: false,
                isOnBlackList: false,
                memname: "",
                mempoint: 0,
                codecnt: 0,
                nowdate: "",
            };
            /**
             * 1. 로그인 여부를 확인합니다.
             * 2. 사용자가 블랙리스트에 있는지를 체크합니다.
             * 3. 블랙리스트에 있으면 리턴하고, 없으면 포인트 체크 단계로 넘어갑니다.
             */
            //로그인 여부 확인
            const getLoginStatusCode = async () => {
                try {
                    const loginResponse = await axios.post(
                        `${apiURL}/api/v1/user/login`
                    );
					if(loginResponse.data.code !== "S0000"){
						throw loginResponse.data.code 
					}
					userStatus.isLogin = true;
                } catch (code) {
                    /* 삼성브라우저  뒤로가기 시 리디렉션 차단 옵션에 의해 aborted 에러가 발생하면서 팝업이 뜹니다. 
					이 에러를 무시합니다. */
                    if (
                        code.code === "ECONNABORTED" &&
                        window.navigator.userAgent.includes("SamsungBrowser")
                    ) {
                        console.error(code);
                        return;
                    }
                    dispatch("popupProcessor/setSelectedPopupByCode", code, {
                        root: true,
                    });
                    return;
                }
            };
            //블랙리스트 체크
            const checkBlackList = async () => {
                if (!userStatus.isLogin) {
                    return;
                }
                try {
                    const response = await axios.get(
                        `${apiURL}/api/v1/user/black`
                    );
                    if (response.data.code === "S0000" && !response.data.data) {
                        return;
                    } else if (response.data.code === "S0000" && response.data.data ) {
                        userStatus.isOnBlackList = true;
                        commit("setBlackList", response.data.data);
                        return;
                    } else {
                        throw response.data.code;
                    }
                } catch (code) {
                    //체크 후 로그인 상태, 블랙리스트 상태 초기화
                    dispatch("popupProcessor/setSelectedPopupByCode", code, {
                        root: true,
                    });
                    return;
                }
            };
            //포인트 체크
            const getUserPointResponse = async () => {
                if (!userStatus.isLogin || userStatus.isOnBlackList) {
                    return;
                }
                try {
                    const getpoints = await axios.get(
                        `${apiURL}/api/v1/mypage/points`
                    );
                    if (getpoints.data.code === "S0000") {
                        userStatus.memname = getpoints.data.data.memname;
                        userStatus.mempoint = getpoints.data.data.mempoint;
                        userStatus.codecnt = getpoints.data.data.codecnt;
                        userStatus.monthend = getpoints.data.data.monthend;
                        userStatus.delpoint = getpoints.data.data.delpoint;
                        userStatus.nowdate = getpoints.data.data.nowdate;
                        return;
                    } else {
                        throw getpoints.data.code;
                    }
                } catch (code) {
                    dispatch("popupProcessor/setSelectedPopupByCode", code, {
                        root: true,
                    });
                    return;
                }
            };

            await getLoginStatusCode();
            await checkBlackList();
            await getUserPointResponse();

            commit("setLoginStatus", {
                isLogin: userStatus.isLogin,
                code: userStatus.loginCode,
                nowdate: userStatus.nowdate,
            });
            commit("setUserPoints", {
                memname: userStatus.memname,
                mempoint: userStatus.mempoint,
                codecnt: userStatus.codecnt,
                monthend: userStatus.monthend,
                delpoint: userStatus.delpoint,
                nowdate: userStatus.nowdate,
            });
        },
    },
    getters: {
        getLoginStatus(state) {
            return state.loginStatus;
        },
        getIsGlobalNavActived(state) {
            return state.isGlobalNavActived;
        },
        getAppData(state) {
            return state.appData;
        },
        getIsApplicationPopupActived(state) {
            return state.isAppicationPopupActived;
        },
        getIsDataLoadingFinished(state) {
            return state.isDataLoadingFinished;
        },
        getIsUserOnBlackList(state) {
            return state.isUserOnBlackList;
        },
        getBlackListDetails(state) {
            return {
                isUserOnBlackList: state.isUserOnBlackList,
                reason: state.blackListDetails.reason,
                startdate: state.blackListDetails.startdate,
            };
        },
        getSavedScrollPosition(state) {
            return state.savedScrollPosition;
        },
        getIsPaymentTableOpened(state) {
            return state.isPaymentTableOpened;
        },
    },
});
