export default [	//account
	{
		path: "/account",
		name: "AccountLogin",
		component: () => import("@/components/accountPage")
		
	},
	{
		path: "/account/join/checkAdult",
		name: "AccountCheckAdult",
		component: () => import("@/components/account/accountCheckAdult"),
		meta:{
			title:'성인여부'
		}
	},
	{
		path: "/account/join/sns",
		name: "AccountSNSJoin",
		component: () => import("@/components/account/accountSNSJoin"),
		meta:{
			title:'소셜 로그인 선택'
		}
	},
	{	
		path: "/account/join/terms",
		name: "AccountTerms",
		component: () => import("@/components/account/accountTerms"),
		meta:{
			title:'약관 동의'
		}
	},
	{
		path: "/account/join/certify",
		name: "AccountCertify",
		component: () => import("@/components/account/accountCertify")
	},
	{
		path: "/account/join/result",
		name: "AccountResult",
		component: () => import("@/components/account/accountResult"),
		meta:{
			title:'가입완료'
		}
	}
]